import React from 'react';

import { Layout } from '../layout/Layout';
import { Link } from 'gatsby';
import messageImg from '../images/spots-mensaje.png';

const title = {
  'en-US': 'Thank you for contacting us!',
  'es-ES': '¡Gracias por contactarnos!',
  'fr-FR': 'Merci pour nous contacter!',
  'pt-BR': 'Obrigado por nos contatar!',
};

const message = {
  'en-US': 'We will contact you as soon as possible.',
  'es-ES': 'Nos comunicaremos contigo lo más pronto posible.',
  'fr-FR': 'Nous vous contacterons dès que possible.',
  'pt-BR': 'Entraremos em contato o mais breve possível.',
};

const button = {
  'en-US': 'Go to home page',
  'es-ES': 'Ir al inicio',
  'fr-FR': "Aller à la page d'accueil.",
  'pt-BR': 'Vá para a página inicial.',
};

const ThanksPage = ({ pageContext }) => {
  return (
    <Layout pageId="thanks" currentLanguage={pageContext.languageCode}>
      <section className="thanks">
        <div className="container">
          <div className="row">
            <div className="col-md-11 mx-auto thanks__container">
              <div>
                <img src={messageImg} />
              </div>
              <h1>{title[pageContext.languageCode]}</h1>
              <p>{message[pageContext.languageCode]}</p>
              <Link to={`/${pageContext.basePath}`} className="btn primary-btn">
                {button[pageContext.languageCode]}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default ThanksPage;
